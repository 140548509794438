@import '../fonts/Roboto/Roboto.css';
@import '../fonts/Rosa-Sans/RosaSans.css';



.title {
    font-family: "Rosa-Sans", serif;
    font-size: 128px;
}

.h1 {
    font-family: "Rosa Sans SemiBold", serif;
    font-size: 64px;
    letter-spacing: -0.01em;
}

.h2 {
    font-family: "Rosa Sans SemiBold", serif;
    font-size: 40px;
    letter-spacing: -0.01em;
}

.h3 {
    font-family: "Rosa Sans SemiBold", serif;
    font-size: 24px;
    letter-spacing: -0.01em;
}

.subTitle {
    font-family: "Rosa Sans Regular", serif;
    font-size: 24px;
    letter-spacing: -0.01em;
}

.bodyLarge {
    font-size: 20px;
    font-weight: 700;
}

.body {
    font-size: 16px;
    font-weight: 400;
    color: var(--accent-color);
}

.bodyLight {
    font-size: 16px;
    font-weight: 300;
}

.bodySmall {
    font-size: 14px;
    font-weight: 400;
    color: var(--accent-color);
}


.textButton {
    font-family: "Roboto Medium", serif;
    font-size: 12px;
}

.button {
    font-family: "Roboto Medium", serif;
    font-size: 10px;
}



