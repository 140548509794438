:root {
    --webHeaderHeigh: 106px;
}


.container{
    align-content: center;
    display: flex;
    margin: auto;
    width: var(--current-screen-width);
    height: var(--webHeaderHeigh);
    background-color: var(--background-color);
}

.divider {
    border-bottom: 1px solid var(--second200);
    width: 100vw;
    position: absolute;
    opacity: 0.5;
    top: var(--webHeaderHeigh);
    left: 0;
}

.logoContainer {
    margin-left: 0;
}

.rightContainer {
    display: flex;
    margin-right: 0;
    width: fit-content;
}

.searchBarContainer {
    margin-left: 180px;
}


@media (max-width: 480px) {

    .searchButtonContainer {
        position: absolute;
        top: 12px;
        right: 12px;
        display: flex;
    }

    .searchButton {
        border: none;
        background: none;
    }

    .container{
        height: 100%;
        align-content: center;
        display: flex;
        padding-top: 12px;
        flex-direction: column;
        padding-bottom: 12px;
        width: 100%;
    }

    .logoContainer {
        margin: auto 24px 12px;
    }

    .rightContainer {
        display: none;
    }

    .divider {
        display: none;
    }

    .searchBarContainer {
        width: 91%;
    }

    .headerContainer {
        border-bottom: none;
    }

    .searchBarContainer {
        margin-left: auto;
    }
}
