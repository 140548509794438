@import '../../../shared/style/sizes.css';
@import '../../../shared/style/colors.css';

:root {
    --large-content-width: 1296px;
    --medium-content-width: 734px;
    --current-content-width: var(--large-content-width);
}

.player {
    position: fixed;
    bottom: 0;
    display: inline-block;
    width: 100%;
    height: var(--header-footer-height);
    box-shadow: 8px 0 20px rgba(0, 0, 0, 0.1);
    background: var(--background-color);
}

.container {
    display: flex;
    flex-direction: column;
    width: var(--current-screen-width);
    height: var(--header-footer-height);
}

.upperContainer {
    width: var(--current-screen-width);
    margin-top: 10px;
    margin-left: 0;
    display: flex;
}

.arrowContainer {
    display: none;
}

.playButtonContainer {
    height: 100%;
}

@media (max-width: 1440px) {
    :root {
        --current-content-width: var(--medium-content-width);
    }
}

@media (max-width: 480px) {

    :root {
        --current-content-width: 100%;
    }

    .container {
        width: 100%;
    }

    .upperContainer {
        width: 100%;
        margin-bottom: 0;
        margin-top: 8px;
    }

    .player {
        height: 84px;
        border-top-right-radius: 12px;
        border-top-left-radius: 12px;
    }

    .arrowContainer {
        display: flex;
        margin-right: 13px;
        margin-top: 9px;
    }

    .playButtonContainer {
        margin-right: 0;
        display: flex;
        width: 35%;
    }


}