
.container {
    display: flex;
    width: 878px;
    flex-direction: column;
    margin-bottom: 36px;
}


.mainTitle {
    font-size: 86px;
    margin-bottom: 64px;
}

.blogPostMarginDivider {
    margin-top: 40px;
}


@media (max-width: 480px) {
    .container {
        width: 100%;
    }

    .mainTitle {
        margin-top: 20px;
        margin-bottom: 12px;
        font-size: 26px;
    }

    .blogPostMarginDivider {
        margin: 0;
    }
}