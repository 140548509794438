@import '../../../../shared/style/colors.css';

.container {
    display: flex;
    padding-right: 0;
    width: fit-content;
    margin-left: 80px;
}

.navItem {
    font-size: 16px;
    font-weight: bold;
    font-family: "Rosa Sans SemiBold", serif;
    text-decoration: none;
    color: var(--secondary-color);
    width: fit-content;
}

.active {
    color: var(--primary-color);
}


.activeDivider {
    display: none;
    position: absolute;
    background: var(--primary-color);
    width: 134px;
    height: 3px;
}

