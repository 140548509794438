@import '../../../../../shared/style/sizes.css';

.container {
    width: var(--medium-screen-width);
    display: flex;
    padding: 0;
}

.unfilledContainer {
    width: var(--medium-screen-width);
    display: inline-block;
}

.podcastElementContainer {
    float: left;
    margin-right: 6.7px;
    margin-left: 6.7px;
}



@media (max-width: 480px) {
    .container {
        width: 320px;
        justify-content: space-between;
        margin-bottom: 6px;
        background: none;
        margin-left: auto;
        margin-right: auto;
    }


}

