@import '../../../shared/style/sizes.css';

.container {
    display: flex;
    flex-direction: column;
    width: var(--medium-screen-width);
    margin: 142px auto auto;
}

@media (max-width: 480px) {
    .container {
        margin-top: 80px;
        width: 100%;
    }
}