
.container {
    display: flex;
    margin-bottom: 12px;
    margin-left: 12px;
}



@media (max-width: 480px) {
    .container {
        padding-left: 0;
        margin-left: 0;
        margin-bottom: 8px;
    }

}