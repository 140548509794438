
:root {
    --imageWidth: 112;
}

.container {
    margin: 8px;
}

.image {
    width: 160px;
    height: 160px;
    border-radius: 10px;
}

@media (max-width: 480px) {
    .image {
        width: 100px;
        height: 100px;
    }

    .container {
        margin: 0;
    }
}