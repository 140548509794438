
.container {
    margin: auto auto 16px auto;
    display: flex;
}

.textContainer {
    margin: auto;
    text-align: center;
}

@media (max-width: 480px) {
    .container {
        font-size: 20px;
        margin-bottom: 14px;
    }
}