
.container {
    display: flex;
}

.imageContainer {
    margin-top: 0;
    margin-right: 0;
    height: 400px;
    width: 400px;
    background: var(--whitePearch);
    border-radius: 12px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.imageContainerReversed {
    margin: 0 auto auto 0;
}


@media (max-width: 480px) {
    .imageContainer {
        margin: 40px auto auto;
        width: 250px;
        height: 250px;
    }

    .imageContainerReversed {
        margin-top: 0;
        margin-bottom: 40px;
    }

    .image {
        width: 250px;
        height: 250px;
    }
}