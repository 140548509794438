@import '../../shared/style/sizes.css';

.container {
    display: flex;
    flex-direction: column;
    width: 500px;
    margin-top: 64px;
    margin-bottom: 100px;
}

.mainTitle {
    font-size: 86px;
    margin-bottom: 48px;
}



.title {
    font-size: 24px;
    width: 800px;
    text-align: left;
    color: var(--second500);
}

.textContainer {
    margin-bottom: 48px;
    width: 500px;
}


.text {
    text-align: left;
    margin-top: 6px;
    line-height: 170%;
    color: var(--accent-color)
}

.companyText {
    color: var(--accent-color);
}

.innovationLogo {
    margin-top: 40px;
    width: 300px;
}

@media (max-width: 480px) {
    .container {
        width: 80%;
        margin-top: 48px;
    }

    .title {
        font-size: 18px;
        width: 100%;
    }

    .mainTitle {
        font-size: 26px;
        margin-bottom: 48px;
    }

    .text {
        margin: 8px auto auto 0;
    }

    .textContainer {
        margin-bottom: 48px;
        width: 100%;
    }

    .innovationLogo {
        margin-top: 16px;
    }

    .companyText {
        margin-top: 20px;
    }

    .companyText:last-of-type {
        margin-top: 6px;
    }
}