.episodeImage {
    width: 48px;
    height: 48px;
    border-radius: 30px;
    margin-left: 12px;
    margin-right: 12px;
}

.episodeDataContainer {
    margin-top: 6px;
    margin-left: 0;
    display: flex;
}

.episodeTexts {
    margin-top: 0;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.episodeTitle {
    margin-top: 3px;
    margin-left: 0;
    text-align: left;
    color: var(--secondary-color);
}

.podcastName {
    margin-left: 0;
    margin-top: 5px;
    text-align: left;
    font-size: 10px;
    color: var(--secondary-color)
}

@media (max-width: 480px) {
    .episodeTitle {
        margin-top: 8px;
        font-family: "Roboto Bold", serif;
        max-width: 50vw;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .podcastName {
        max-width: 50vw;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}