
.container {
    flex-direction: column;
    z-index: 500;
    position: fixed;
    display: flex;
    top: 0;
    background: #FFFFFF;
    width: 100vw;
    height: 100%;
}

.closeButtonContainer {
    display: flex;
    vertical-align: middle;
    height: 50px;
    width: 100%;
    margin-bottom: 34px;
    margin-top: 0;
}

.closeButton {
    margin-top: 20px;
    margin-right: 20px;
    cursor: pointer;
    border: none;
    background: none;
    padding: 0;
}


.episodeImageContainer {
    margin-top: 0;
    display: flex;
    height: 300px;
    width: 300px;
    margin-bottom: 24px;
    border-radius: 10px;
}


.episodeTitleContainer {
    margin-top: 0;
    display: flex;
    margin-bottom: 8px;
    text-align: center;
    width: 80%;
}

.episodeTitle{
    color: var(--second500);
    font-size: 16px;
    font-family: "Roboto Medium", serif;
}

.podcastNameContainer {
    margin-top: 0;
    margin-bottom: 40px;
}

.podcastName {
    font-size: 12px;
}

.timeContainerContainer {
    margin-top: 24px;
    width: 100%;
}