
.container {
    display: flex;
    width: 1000px;
    height: 540px;
}


.rightContainer {
    display: flex;
    width: 50%;
    height: 100%;
    margin: 0;
}

@media (max-width: 480px) {
    .container {
        flex-direction: column;
        width: 85%;
        text-align: center;
        height: fit-content;
    }

    .rightContainer {
        margin: auto;
        width: 80%;
    }
}

