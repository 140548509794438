@import "../../../shared/style/colors.css";
@import "../../../shared/style/mobileColors.css";

.container {
    margin: 32px auto auto;
    width: 650px;
    text-align: center;
}

.title {
    color: var(--secondary-color);
}


.subTitle {
    color: var(--accent-color);
    margin-top: 32px;
}

.span {
    color: var(--primary-color);
    font-family: "Rosa Sans SemiBold", serif;
    font-size: 64px;
    letter-spacing: -0.01em;
}



@media (max-width: 480px) {
    .container {
        width: 100%;
        margin: 60px auto 0;
    }

    .title {
        width: 280px;
        font-size: 36px;
        color: var(--second500);
    }

    .span {
    color: var(--primary-color);
    font-family: "Rosa Sans SemiBold", serif;
    font-size: 36px;
    letter-spacing: -0.01em;
}


    .subTitle {
        font-family: "Roboto", serif;
        color: var(--second400);
        opacity: 0.85;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.5;
        margin-top: 24px;
        width: 90%;
    }

}