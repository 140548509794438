

.container {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 50px;
}

.title {
    display: inline-block;
    letter-spacing: 0.01em;
    margin-bottom: 12px;
    font-size: 18px;
}

.body {
    color: var(--accent-color)
}


.query {
    margin-left: 2px;
    margin-right: 4px;
    color: var(--primary-color);
    font-size: 18px;
}

@media (max-width: 480px) {
    .container {
        width: 80%;
        margin-top: 16px;
        margin-bottom: 16px;
    }

    .title {
        font-size: var(--m);
        color: var(--second500);
        text-align: center;
        margin-bottom: 16px;
    }

    .query {
        margin: 0 2px;
        font-size: var(--m);
        text-align: center;
        color: var(--main500)
    }

    .body {
        width: 85%;
        text-align: center;
        font-size: var(--s);
    }

}