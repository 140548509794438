@import '../../../shared/style/colors.css';

.logo {
    font-family: "Roboto Medium", serif;
    margin: auto;
    color: var(--primary-color);
    font-size: 32px;
    text-decoration: none;
    width: fit-content;
}
