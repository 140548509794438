@import "./colors.css";
@import '../fonts/Rosa-Sans/RosaSans.css';
@import '../fonts/Roboto/Roboto.css';

body {
    background-color: var(--background-color);
}

* {
    font-family: "Roboto", serif;
    margin: auto;
    -webkit-tap-highlight-color: transparent;
}

