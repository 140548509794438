@import '../../shared/style/sizes.css';

.cky-banner-element {
    padding: 8px 30px;
    background: #F8F9FA;
    color: #858A8F;
    border: 1px solid #DEE2E6;
    box-sizing: border-box;
    border-radius: 2px;
    cursor: pointer;
}

.mainTitle {
    font-size: 72px;
    margin-bottom: 48px;
}


.container {
    display: flex;
    flex-direction: column;
    width: 500px;
    margin-top: 64px;
    margin-bottom: 100px;
}

.cookieSettingsContainer {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 12px;
}

.cookieSettingsButton {
    color: black;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
}


.textContainer {
    margin-bottom: 48px;
    width: 500px;
}


.title {
    font-size: 24px;
    width: 800px;
    text-align: left;
    color: var(--second500);
}

.textContainer {
    margin-bottom: 48px;
    width: 500px;
}

.text {
    text-align: left;
    margin-top: 6px;
    line-height: 170%;
    color: var(--accent-color)
}

@media (max-width: 480px) {

    .container {
        width: 80%;
        margin-top: 48px;
    }

    .title {
        font-size: 18px;
        width: 100%;
    }

    .mainTitle {
        font-size: 26px;
        margin-bottom: 48px;
    }

    .text {
        margin: 8px auto auto 0;
        width: 100%;

    }

    .textContainer {
        margin-bottom: 48px;
        width: 100%;
    }

    .anchorTag {
        word-wrap: break-word;
    }



}