
:root {
    --large-screen-width: 1440px;
    --medium-screen-width: 878px;
    --header-footer-height: 80px;

    --current-screen-width: var(--large-screen-width);
}

@media (max-width: 1440px) {
    :root {
        --current-screen-width: var(--medium-screen-width);
    }
}

@media (max-width: 480px) {
    :root {
        --current-screen-width: 100%;
    }
}