
.container {
}


.sortContainer {
    float: left;
    margin-left: 35px;
}


.activeFiltersContainer {
    position: absolute;
    margin-top: 16px;
    margin-left: 12px;
}

.invisible {
    visibility: hidden;
}


@media (max-width: 480px) {
    .searchContainer {
        width: 100%;
        margin: 64px auto auto;
    }

    .activeFiltersContainer {
        position: relative;
        margin-left: 4%;
        margin-bottom: 6px;
    }

}

