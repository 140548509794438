@import '../../../../shared/style/sizes.css';

.container{
    margin: auto auto auto 0;
    width: var(--medium-screen-width);
}

@media (max-width: 480px) {
    .container {
        width: 100%;
        margin: auto;
    }
}