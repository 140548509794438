
.container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 200px;
    margin-right: 12px;
}


.imageContainer {
    display: flex;
    margin-bottom: 0;
}

.image {
    width: 200px;
    height: 200px;
    border-radius: 10px;
}

.titleContainer {
    width: 180px;
    font-size: 16px;
    color: var(--second500);
    margin-left: 0;
    margin-bottom: 0;
    margin-top: 10px;
}

.productionCompanyContainer {
    margin-top: 4px;
    color: var(--second400);
    font-size: 14px;
    margin-left: 0;
}

.container:last-of-type {
    margin-right: 0;
}

@media (max-width: 480px) {
    .container {
        margin: auto;
        width: 80px;
    }

    .titleContainer {
        margin-left: 0;
        margin-right: 0;
        width: 80px;
        font-size: 12px;
        height: fit-content;
    }

    .productionCompanyContainer {
        font-size: 10px;
        margin-right: 0;
        width: 80px;
    }

    .image {
        width: 80px;
        height: 80px;
        margin-top: 0;
    }


    .imageContainer {
        margin-left: 0;
        margin-top: 0;
    }

    .container:last-of-type {
        margin: auto;
    }

}