@import '../../style/mobileColors.css';

.inputContainer {
    height: 40px;
    display: table-cell;
    vertical-align: middle;
}

.container {
    margin-top: 0;
    display: table;
    height: 50px;
    width: 100%;
    border-bottom: 1px solid var(--second200);
}


.backButtonContainer {
    display: table-cell;
    vertical-align: middle;
    height: 100%;
    width: 1%;
}

.backButton {
    display: none;
    margin-left: 3.5vw;
    padding: 0;
    margin-right: 3.5vw;
    border: none;
    background: none;
}

.image {
    position: relative;
    transform: translateY(1px);
}

.searchIconContainer {
    display: flex;
    height: 100%;
    margin-left: 4px;
}


.input {
    height: 100%;
    width: 100%;
    padding: 0;
    margin-left: 8px;
    border: none;
    background: none;
    outline: none;
    color: var(--second600);
    font-size: 14px;
}

.input::placeholder {
    font-size: 14px;
}

.closeButtonContainer {
    display: table-cell;
    vertical-align: middle;
    height: 100%;
    width: 10%;
}


.closeButton {
    transform: scale(0.8);
    cursor: pointer;
    margin-left: 0;
    border: none;
    background: none;
}

@media (max-width: 480px) {
    .input {
        font-size: 16px;
    }

    .input::placeholder {
        font-size: 16px;
    }

    .inputContainer {
        height: 100%;
        width: 60%;
    }

    .backButton {
        display: inline-block;
    }

    .searchIconContainer {
        display: none;
    }


    .closeButton {
        transform: scale(1);
    }


}

