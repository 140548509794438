@import '../fonts/Roboto/Roboto.css';
@import '../fonts/Rosa-Sans/RosaSans.css';

:root {
    --xl: 24px;
    --l: 20px;
    --m: 16px;
    --s: 12px;
    --xs: 10px;
}

.textRegularXl {
    font-family: "Roboto", serif;
    font-size: var(--xl);
}

.textRegularL {
    font-family: "Roboto", serif;
    font-size: var(--l);
}

.textRegularM {
    font-family: "Roboto", serif;
    font-size: var(--m);
}

.textRegularS {
    font-family: "Roboto", serif;
    font-size: var(--s);
}

.textRegularXs {
    font-family: "Roboto", serif;
    font-size: var(--xs);
}

.textMediumXl {
    font-family: "Roboto Medium", serif;
    font-size: var(--xl);
}

.textMediumL {
    font-family: "Roboto Medium", serif;
    font-size: var(--l);
}

.textMediumM {
    font-family: "Roboto Medium", serif;
    font-size: var(--m);
}

.textMediumS {
    font-family: "Roboto Medium", serif;
    font-size: var(--s);
}

.textMediumXs {
    font-family: "Roboto Medium", serif;
    font-size: var(--xs);
}