
.container {
    z-index: 100;
    position: absolute;
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    width: 155px;
    min-width: 120px;
    margin-top: 4px;
    border-radius: 12px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}


.valuesContainer {
    border-radius: 12px;
    width: 100%;
    display: flex;
    flex-direction: column;
    background: var(--whitePearch);
    height: 100%;
    font-size: 13px;
    text-align: left;
}


.valueContainer {
    display: flex;
    height: 30px;
    width: 100%;
    cursor: pointer;
    margin: 0;
}

.valueContainer:first-of-type {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.valueContainer:last-of-type {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}

.value {
    margin-left: 30px;
    color: var(--second400);
}


.activeValueContainer {
    background-color: rgba(14, 13, 19, 0.03);
}

.activeValue {
    color: var(--second500);
    margin-left: 0;
}

.circleContainer {
    width: 20px;
    display: flex;
    height: 100%;
    margin-right: 2px;
    margin-left: 8px;
}

.circle {
    width: 6px;
    height: 6px;
    background: var(--second500);
    border-radius: 50%;
    margin: auto 6px;
}


.valueContainer:hover {
    background-color: rgba(14, 13, 19, 0.03);
}

@media (max-width: 480px) {

    .container {
        background: var(--whitePearch);
    }

    .valuesContainer {
        font-size: 13px;
        padding-right: 0;
        background: var(--whitePearch);
    }

    .valueContainer {
        height: 36px;
        margin-right: 0;
        background: var(--whitePearch);
    }

    .activeValueContainer {
        background-color: #faf8f8;
    }

    .valueContainer:hover {
        background-color: #faf8f8;
    }


}