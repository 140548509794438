@import '../../shared/style/sizes.css';

.container {
    width: var(--medium-screen-width);
    margin-bottom: 126px;
}

.searchContainer {
    margin-top: 100px;
    width: 640px;
}

.infoContainer {
    margin-top: 270px;
    margin-bottom: 580px;
}

.topSearchesContainer {
    margin-top: 880px;
    margin-bottom: 500px;
}

.bottomInfoContainer {
    margin-top: 206px;
    margin-bottom: 400px;
}

.collaborationsContainer {
    margin-top: 420px;
    margin-bottom: 400px;
}

.adBanner {
    padding: 14px;
    width: 850px;
    height: fit-content;
    background: var(--main100);
    border-radius: 10px;
}

.adImage {
    border-radius: 10px;
}

.infoContainerLast {
    margin-bottom: 200px;
}

.helloFreshBannerContainer {
    width: 1000px;
    padding: 18px;
    padding-top: 8px;
    display: flex;
    background: var(--whitePearch);
    border-radius: 10px;
}

@media (max-width: 480px) {
    .container {
        width: 91%;
        margin: auto;
    }

    .infoContainer {
        margin-top: 150px;
        margin-bottom: 92px;
    }

    .helloFreshSquareContainer {
        margin-top: 150px;
        width: 300px;
        padding: 8px 18px 18px;
        display: flex;
        background: var(--main100);
        border-radius: 10px;
    }

    .topSearchesContainer {
        margin-top: 150px;
        margin-bottom: 92px;
    }

    .bottomInfoContainer {
        margin-top: 100px;
        margin-bottom: 92px;
    }

    .collaborationsContainer {
        margin-top: 92px;
        margin-bottom: 92px;
    }

    .searchContainer {
        margin-top: 30px;
        width: 100%;
    }

    .searchButtonContainer {
        margin-top: 40px;
        margin-bottom: 100px;
    }


}