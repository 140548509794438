
.container {
    z-index: 100;
    position: absolute;
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    width: 310px;
    height: 230px;
    overflow:auto;
    margin-top: 52px;
    margin-left: -94px;
    border-radius: 12px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.podcastsContainer {
    width: 100%;
    background: var(--whitePearch);
    height: 100%;
}

@media (max-width: 480px) {
    .container {
        height: fit-content;
        width: 100%;
        left: 0;
        top: 0;
        min-height: 100vh;
        margin: 0;
    }

    .podcastsContainer {
        min-height: 100vh;
        width: 91%
    }

}