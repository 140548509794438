
    @font-face {
    font-family: 'Rosa Sans Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Rosa Sans Regular'), url('./woff/hinted-RosaSans-Regular.woff') format('woff'),
    url('./ttf/hinted-RosaSans-Regular.ttf') format('truetype');
    }
    

    @font-face {
    font-family: 'Rosa Sans Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Rosa Sans Italic'), url('./woff/hinted-RosaSans-Italic.woff') format('woff'),
    url('./ttf/hinted-RosaSans-Italic.ttf') format('truetype');
    }
    

    @font-face {
    font-family: 'Rosa Sans Light';
    font-style: normal;
    font-weight: normal;
    src: local('Rosa Sans Light'), url('./woff/hinted-RosaSans-Light.woff') format('woff'),
    url('./ttf/hinted-RosaSans-Light.ttf') format('truetype');
    }
    

    @font-face {
    font-family: 'Rosa Sans Light Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Rosa Sans Light Italic'), url('./woff/hinted-RosaSans-LightItalic.woff') format('woff'),
    url('./ttf/hinted-RosaSans-LightItalic.ttf') format('truetype');
    }
    

    @font-face {
    font-family: 'Rosa Sans Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Rosa Sans Medium'), url('./woff/hinted-RosaSans-Medium.woff') format('woff'),
    url('./ttf/hinted-RosaSans-Medium.ttf') format('truetype');
    }
    

    @font-face {
    font-family: 'Rosa Sans Medium Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Rosa Sans Medium Italic'), url('./woff/hinted-RosaSans-MediumItalic.woff') format('woff'),
    url('./ttf/hinted-RosaSans-MediumItalic.ttf') format('truetype');
    }
    

    @font-face {
    font-family: 'Rosa Sans SemiBold';
    font-style: normal;
    font-weight: normal;
    src: local('Rosa Sans SemiBold'), url('./woff/hinted-RosaSans-SemiBold.woff') format('woff'),
    url('./ttf/hinted-RosaSans-SemiBold.ttf') format('truetype');
    }
    

    @font-face {
    font-family: 'Rosa Sans SemiBold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Rosa Sans SemiBold Italic'), url('./woff/hinted-RosaSans-SemiBoldItalic.woff') format('woff'),
    url('./ttf/hinted-RosaSans-SemiBoldItalic.ttf') format('truetype');
    }
    

    @font-face {
    font-family: 'Rosa Sans Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Rosa Sans Bold'), url('./woff/hinted-RosaSans-Bold.woff') format('woff'),
    url('./ttf/hinted-RosaSans-Bold.ttf') format('truetype');
    }
    

    @font-face {
    font-family: 'Rosa Sans Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Rosa Sans Bold Italic'), url('./woff/hinted-RosaSans-BoldItalic.woff') format('woff'),
    url('./ttf/hinted-RosaSans-BoldItalic.ttf') format('truetype');
    }
    

    @font-face {
    font-family: 'Rosa Sans Black';
    font-style: normal;
    font-weight: normal;
    src: local('Rosa Sans Black'), url('./woff/hinted-RosaSans-Black.woff') format('woff'),
    url('./ttf/hinted-RosaSans-Black.ttf') format('truetype');
    }
    

    @font-face {
    font-family: 'Rosa Sans Black Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Rosa Sans Black Italic'), url('./woff/hinted-RosaSans-BlackItalic.woff') format('woff'),
    url('./ttf/hinted-RosaSans-BlackItalic.ttf') format('truetype');
    }