
.container {
    display: flex;
    height: 64px;
    padding-left: 12px;
    padding-right: 19px;
    border-radius: 15px;
    transition: background-color 0.05s linear;
    cursor: pointer;
}

.container:hover {
    background-color: rgba(14, 13, 19, 0.03);
}

.rightContainer {
    display: flex;
    flex-direction: column;
    margin: 13px auto auto 8px;
    width: 814px;
}

.upperContainer {
    display: flex;
    margin: 2px auto 2px 0;
    width: 100%;
}


.title {
    color: var(--secondary-color);
    font-family: "Roboto Medium", serif;
    font-size: 12px;
    margin-top: 0;
    margin-right: 0;
    width: 100%;
    text-align: left;
}

.lowerContainer {
    display: flex;
    color: var(--accent-color);
    margin: 1px auto auto 0;
}


.imageContainer {
    display: flex;
    margin: 0;
    width: 48px;
}

.image {
    border-radius: 10px;
    margin: auto;
    width: 48px;
    height: 48px;
}

@media (max-width: 480px) {
    .container {
        padding: 0;
        width: 100%;
        height: 64px;
        border-bottom: 1px solid #C2C6CC;
        border-radius: 0;
    }


    .rightContainer {
        margin-top: 16px;
        margin-bottom: 0;
        margin-left: 18px;
    }

    .metadata {
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .fullContainer {
        width: 100%;
    }

    .title {
        font-size: var(--s);
        width: 80%;
        margin-left: 0;
        color: var(--second500);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 300px;
    }

    .upperContainer {
        width: 100%;
        height: fit-content;
        margin-bottom: 0;
    }

    .lowerContainer {
        font-size: var(--xs);
        margin-top: 4px;
        margin-left: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 16px;
    }

    .image {
        margin-left: 8px;
        width: 48px;
        height: 48px;
    }


}