
.container {
    width: 878px;
    height: 224px;
    background: var(--whitePearch);
    border-radius: 12px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding-top: 50px;
}

.titleContainer  {
    display: flex;
    width: 100%;
    height: fit-content;
}

.title {
    font-family: "Rosa Sans SemiBold", serif;
    color: var(--second400);
    font-size: 24px
}

.collaborationsContainer {
    margin-top: 34px;
    display: flex;
    width: 650px;
}

.imageContainer {
    display: flex;
    width: 200px;
}

.innovationLogo {
    width: 200px;
}

.pdLogo {
    width: 160px;
}

.aosLogo {
    width: 200px;
}


@media (max-width: 480px) {
    .container {
        width: 70%;
        height: 390px;
    }

    .collaborationsContainer {
        margin-top: 12px;
        width: 100%;
        flex-direction: column;
        height: 300px;
    }

    .imageContainer {
        flex-direction: row;
    }



}


