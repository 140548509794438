:root {
    --whitePearch: #FFFFFF;

    --main900: #7D2C00;
    --main800: #993600;
    --main700: #BF4400;
    --main600: #E65100;
    --main500: #FF5A00;
    --main400: #FF8C4D;
    --main300: #FFBD99;
    --main200: #FFD6BF;
    --main100: #FFEFE6;

    --second900: #050D19;
    --second800: #07101F;
    --second700: #081426;
    --second600: #0A172E;
    --second500: #0B1A33;
    --second400: #545F70;
    --second300: #9DA3AD;
    --second200: #C2C6CC;
    --second100: #E7E8EB;
}