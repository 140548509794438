@import '../../../../../shared/style/colors.css';
@import '../../../../../shared/style/sizes.css';

.container {
    display: flex;
    width: var(--medium-screen-width);
    height: fit-content;
    margin-bottom: 30px;
}

.playButton {
    display: flex;
    width: 66px;
    height: 24px;
    cursor: pointer;
    background: var(--second100);
    border: none;
    border-radius: 30px;
    padding-top: 0;
    padding-bottom: 0;
}

.buttonContainer {
    padding-left: 20px;
    display: flex;
    width: 100%;
}

.imageArrowContainer {
    margin-left: 8px;
    display: flex;
}

.timestampContainer {
    display: flex;
}

.timestamp {
    font-family: "Roboto Medium", serif;
    display: flex;
    text-align: center;
    color: var(--second500);
    font-size: 10px;
    margin-right: 4px;
    letter-spacing: 0.02em;
}

.queryText {
    font-family: "Roboto Medium", serif;
    color: var(--second600);
    font-weight: 700;
}

.leftContainer {
    display: flex;
    width: 64px;
    margin-top: 0;
    margin-left: 14px;
    margin-right: 12px;
}

.rightContainer {
    margin-top: 4px;
    display: flex;
    flex-direction: column;
    margin-left: 0;
}

.timestampAndQuery {
    font-size: 12px;
    color: var(--accent-color)
}

@media (max-width: 480px) {

    .container {
        width: 91%;
        margin-bottom: 0;
        margin-left: 0;
        height: 50px;
    }

    .playButton {
        height: 26px;
        padding-top: 4px;
        padding-bottom: 4px;
        padding-right: 8px;
        width: 70px;
    }

    .timestamp {
        margin-left: 4px;
    }

    .leftContainer {
        margin-left: 0;
    }


    .rightContainer {
        margin-top: 5px;
    }


}
