@import '../../../../shared/style/sizes.css';
@import '../../../../shared/style/mobileColors.css';
@import '../../../../shared/style/colors.css';
@import '../../../../shared/fonts/mobileFonts.module.css';

.container {
    display: flex;
    width: var(--medium-screen-width);
    height: 90px;
    padding-left: 12px;
    padding-right: 19px;
    border-radius: 15px;
    transition: background-color 0.05s linear;
    cursor: pointer;
}

.fullContainer {
    margin-bottom: 9px;
}

.container:hover {
    background-color: rgba(14, 13, 19, 0.03);
}

.rightContainer {
    display: flex;
    flex-direction: column;
    margin: 13px auto auto 0;
    width: 814px;
}

.upperContainer {
    display: flex;
    margin: 0 auto auto 0;
    height: 25px;
    width: 814px;
}

.arrowContainer {
    display: flex;
}

.openedArrow {
    transform: rotate(180deg);
}

.tokenFilterContainer {
    display: flex;
    width: fit-content;
    margin-left: 91px;
    margin-top: 10px;
}

.tokenFilter {
    display: flex;
    border-radius: 30px;
    height: 24px;
    padding-left: 14px;
    padding-right: 14px;
    width: fit-content;
    margin-right: 8px;
    border: 1px solid var(--second200);
    cursor: pointer;
    outline: none;
    background: none;
}

.tokenFilterActive {
    background: var(--second100);
    height: 26px;
    padding-left: 15px;
    padding-right: 15px;
    border: none;
}

.tokenFilterActive .tokenFilterText{
    color: var(--second500)
}


.tokenFilterText {
    font-size: 12px;
    color: var(--second300);
}

.pageNumberContainer {
    margin-top: 30px;
    width: fit-content;
    display: flex;
}

.pageNumber {
    font-size: 14px;
    margin-right: 3px;
    margin-left: 3px;
    color: var(--second300);
    cursor: pointer;
}

.pageNumberActive {
    font-family: "Roboto Medium", serif;
    font-weight: 700;
    color: var(--second500);
}

.resultsContainer {
    margin-bottom: 40px;
}

.hitsContainer {
    margin-top: 20px;
    height: 240px;
}

.numHits {
    display: flex;
    color: var(--secondary-color);
    margin-top: 0;
    margin-right: 0;
    margin-left: auto;
    text-align: center;
}

.numHitsText {
    font-family: "Roboto Medium", serif;
    font-size: 14px;
    margin-right: 15px;
}

.title {
    color: var(--secondary-color);
    font-family: "Roboto Medium", serif;
    font-size: 16px;
    margin-top: 0;
    margin-left: 15px;
    margin-right: 0;
    width: 600px;
    text-align: left;
    text-overflow: ellipsis;
    max-width: 550px;
    overflow: hidden;
    white-space: nowrap;
}

.lowerContainer {
    display: flex;
    color: var(--accent-color);
    margin: 1px auto auto 15px;
}

.circle {
    width: 4px;
    height: 4px;
    background: var(--accent-color);
    border-radius: 50%;
    margin-right: 6px;
    margin-left: 6px;
}

.imageContainer {
    display: flex;
    margin: 0;
    width: 64px;
}

.image {
    border-radius: 10px;
    margin: auto;
    width: 64px;
    height: 64px;
}

@media (max-width: 480px) {
    .container {
        padding: 0;
        width: 100%;
        height: 64px;
        border-bottom: 1px solid #C2C6CC;
        border-radius: 0;
    }

    .podcastName {
        max-width: 110px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .fullContainer {
        width: 100%;
        margin-bottom: 8px;
    }

    .title {
        font-size: var(--s);
        width: 70%;
        color: var(--second500);
        margin-left: 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 180px;
    }

    .upperContainer {
        width: 100%;
        height: 22px;
        margin-top: 2px;
    }

    .lowerContainer {
        font-size: var(--xs);
        margin-left: 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .numHits {
        display: flex;
        margin-right: 0;
        width: 28%;
    }

    .numHitsText {
        margin-left: 0;
        font-size: var(--s);
    }

    .arrowContainer {
        margin-right: 0;
    }

    .arrowImage {
        width: 8px;
    }

    .image {
        width: 48px;
        height: 48px;
    }

    .pageNumberContainer {
        margin-top: 10px;
    }

    .pageNumber {
        font-size: 13px;
    }

    .tokenFilterContainer {
        margin-top: 20px;
        margin-left: 0;
    }

    .tokenFilter {
        padding-top: 4px;
        padding-bottom: 4px;
        height: 18px;
    }

    .tokenFilterText {
        font-size: 11px;
    }


    .resultsContainer {
        margin-bottom: 20px;
    }

}