
.container {
    display: flex;
    width: 878px;
    padding: 24px;
    flex-direction: column;
    border-bottom: 1px solid #C2C6CC;
}


.headerImageContainer {
    width: 100%;
    height: 300px;
    overflow: hidden;
    border-radius: 10px;
    margin-bottom: 34px;
}

.headerImage {
    width: 100%;
}

.titleContainer {
    display: flex;
    margin-left: 0;
    margin-bottom: 14px;
}

.title {
    font-size: 32px;
    font-family: "Roboto Medium", serif;
}

.section {
    width: 100%;
    margin-left: 0;
}

.body {
    font-size: 14px;
    color: var(--second400);
    line-height: 220%;
    letter-spacing: 0.02em;
}

.subTitle {
    margin-top: 24px;
    margin-bottom: 8px;
    font-size: 20px;
    line-height: 115%;
    color: var(--second500);
}

.buttonsContainer {
    margin-left: 0;
    width: fit-content;
    display: flex;
}

.buttonTextContainer {
    color: var(--whitePearch);
}

.buttonsSpacer {
    width: 10px;
    height: 10px;
}

.bottomButtonContainer {
    display: flex;
    width: 100%;
    margin: auto;
}

.sectionButton {
    font-size: 13px;
    margin: 20px auto 10px 30px;
    cursor: pointer;
    display: flex;
    height: 34px;
    text-align: center;
    line-height: 150%;
    width: fit-content;
    padding-left: 16px;
    padding-right: 16px;
    outline: none;
    background: none;
    border: none;
    background: var(--main400);
    border-radius: 30px;
    text-decoration: none;
}

.bottomButton {
    font-size: 14px;
    margin: 20px auto 10px auto;
    cursor: pointer;
    display: flex;
    height: 40px;
    width: fit-content;
    padding-left: 20px;
    padding-right: 20px;
    outline: none;
    background: none;
    border: none;
    background: var(--main400);
    border-radius: 30px;
    text-decoration: none;
}

.sectionButton:first-of-type {
    margin: 20px auto 10px auto;
}


@media (max-width: 480px) {
    .container {
        width: 80%;
    }

    .buttonsContainer {
        flex-direction: column;
        width: 100%;
    }

    .sectionButton {
        margin-left: 0;
        height: fit-content;
        padding: 8px 16px;
    }

    .sectionButton:first-of-type {
        margin-left: 0;
    }

    .headerImageContainer{
        max-height: 150px;
        height: fit-content;
        margin-bottom: 30px;
    }

}