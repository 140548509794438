.container {
    position: absolute;
    top: 0;
    width: 100vw;
    height: 90vh;
    z-index: -1;
}




.background:first-of-type {
    margin-top: 570px;
    height: 1300px;
    clip-path: polygon(0 12%, 100% 0, 100% 92%, 0 100%);

}

.background {
    margin-top: 500px;
    overflow-x: hidden;
    height: 1910px;
    clip-path: polygon(0 15%, 100% 0, 100% 90%, 0 100%);
}

.background:last-of-type {
    margin-top: 620px;
    height: 800px;
    clip-path: polygon(0 15%, 100% 0, 100% 92%, 0 100%);
}


@media (max-width: 480px) {

    .container {
        height: fit-content;
        overflow-x: hidden;
    }

    .container {
        width: 100%;
    }

    .background:first-of-type {
        height: 640px;
        margin-top: 260px;
        clip-path: polygon(0 30%, 100% 0, 100% 90%, 0 100%);
    }

    .background {
        height: 550px;
        width: 1200px;
        margin-top: 780px;
        clip-path: polygon(0 15%, 100% 0, 100% 80%, 0 100%);
    }


    .background:last-of-type {
        margin-top: 560px;
        height: 650px;
        clip-path: polygon(0 15%, 100% 0, 100% 90%, 0 100%);
    }




}