@font-face {
    font-family: "Roboto";
    src: url('./ttf/Roboto-Regular.ttf') format("truetype"),
    url('./woff/Roboto-Regular-webfont.woff') format("woff");
}

@font-face {
    font-family: "Roboto Black";
    src: url('./ttf/Roboto-Black.ttf') format("truetype");
}

@font-face {
    font-family: 'Roboto Bold';
    src: url('./ttf/Roboto-Bold.ttf') format("truetype");
}

@font-face {
    font-family: 'Roboto Medium';
    src: url('./ttf/Roboto-Medium.ttf') format("truetype"),
    url('./woff/Roboto-Medium-webfont.woff') format("woff");

}