@import "../../../shared/style/colors.css";

.loaderAnimation {
    display: flex;
    position: relative;
    width: 50px;
    height: 50px;
    margin: auto;
    text-align: center;
}
.loaderAnimation div {
    margin: auto;
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 50px;
    height: 50px;
    border: 4px solid var(--primary-color);
    border-radius: 50%;
    animation: loaderAnimation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--primary-color) transparent transparent transparent;
}

.loaderAnimation div:nth-child(1) {
    animation-delay: -0.45s;
}
.loaderAnimation div:nth-child(2) {
    animation-delay: -0.3s;
}
.loaderAnimation div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes loaderAnimation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
