@import '../../../../shared/style/sizes.css';
@import '../player.module.css';


.volumeContainer {
    cursor: pointer;
    --width: 130px;
    display: flex;
    width: var(--width);
    height: 10px;
    margin-right: calc((var(--current-screen-width) - var(--current-content-width)) / 2);
}

.volumeBar {
    border-radius: 30px;
    width: var(--width);
    --seek-before-width: var(--width);
    appearance: none;
    background: #D6D9DC;;
    cursor: pointer;
    height: 10px;
}

.volumeBar::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 8px;
    width: 8px;
}

.volumeBar::-moz-range-thumb {
    -webkit-appearance: none;
    height: 8px;
    width: 8px;
}

.volumeBar::before {
    border-radius: 30px;
    content: '';
    height: 10px;
    width: var(--seek-before-width);
    background-color: var(--primary-color);
    position: absolute;
}

@media (max-width: 480px) {
    .volumeContainer {
        display: none;
    }
}