
.container {
    display: flex;
    flex-direction: column;
    width: 625px;
    height: 370px;
    background: var(--whitePearch);
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 12px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.titleContainer{
    display: flex;
    margin-top: 24px;
    margin-bottom: 24px;
}

.title {
    font-size: 24px;
    font-family: "Rosa Sans SemiBold", serif;
    color: var(--second500);
}

.podcastsContainer {
    margin-top: 0;
    display: flex;
}

@media (max-width: 480px) {
    .container {
        padding-left: 4px;
        padding-right: 4px;
        width: 80%;
        height: 270px;

    }

    .titleContainer {
        margin-top: 24px;
        margin-bottom: 24px;
    }

    .podcastsContainer {
        display: flex;
        width: 300px;
    }

}
