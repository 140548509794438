
.container {
    position: fixed;
    display: flex;
    z-index: 100;
    width: 100vw;
    height: 100%;
    background: #FFFFFF;
    left: 0;
    top: 0;
}