@import '../../../shared/style/colors.css';

.container {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.topResultTitle {
    letter-spacing: 0.02em;
    color: var(--secondary-color);
    margin: auto auto 25px 0;
}

.otherResultsTitle {
    letter-spacing: 0.02em;
    color: var(--secondary-color);

    margin: 40px auto 25px 0;
}


.inResultsBannerContainer {
    width: 909px;
    display: flex;
    margin-bottom: 9px;
}

.helloFreshBanner {
    width: 80%;
}

.bookBeatBanner {
    width: 80%;
}

.nextoryBanner {
    width: 80%;
}

@media (max-width: 480px) {
    .container {
        width: 91%;
    }

    .topResultTitle {
        margin-bottom: 8px;
    }

    .otherResultsTitle {
        margin-top: 16px;
        margin-bottom: 8px;
    }

    .inResultsBannerContainer {
        border-radius: 10px;
        width: 100%;
        display: flex;
        margin-bottom: 0;
        padding-bottom: 10px;
        padding-top: 2px;
    }

    .helloFreshBanner {
        width: 95%;
    }

    .bookBeatBanner {
        width: 95%;
    }

    .nextoryBanner {
        width: 95%;
    }
}