@import '../player.module.css';

.timeContainer {
    --width: var(--current-content-width);
    width: var(--width);
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
}

.timeTextsContainer {
    display: flex;
    width: var(--current-content-width);
    position: absolute;
    bottom: 10px;
}

.timeElapsed {
    margin-left: 0;
    font-size: 10px;
}

.timeRemaining {
    margin-right: 0;
    font-size: 10px;
}

.timeBar {
    --seek-before-width: 0;
    width: var(--current-content-width);
    appearance: none;
    background: #D6D9DC;;
    cursor: pointer;
    height: 10px;
    border-radius: 10px;
}

.timeBar::-webkit-slider-thumb {
    -webkit-appearance: none;
    visibility: hidden;
    background: var(--main500);
    border: var(--second200);
    height: 16px;
    width: 16px;
    border-radius: 50%;
    cursor: pointer;
}

.timeBar::before {
    content: '';
    height: 10px;
    width: var(--seek-before-width);
    background-color: var(--primary-color);
    position: absolute;
    z-index: 2;
    cursor: pointer;
    border-radius: 10px;
}

.timeElapsedOnHoverContainer {
    display: none;
    text-align: center;
    position: absolute;
    width: 44px;
    height: 22px;
    z-index: 3;
    bottom: 16px;
    font-size: 10px;
    border-radius: 20px;
    background: #FF5A00;
    color: white;
}

.timeElapsedOnHover {
    margin: auto;
}





@media (max-width: 480px) {

    .timeBarContainer {
        width: 100%;
        display: flex;
    }

    .timeBar {
        /*border-radius: 0;*/
        width: 93%;
        height: 6px;
        --timeBarThumbVisible: hidden;
    }

    .timeBar:before {
        height: 6px;
    }

    /*.timeBar::before {*/
    /*    border-bottom-left-radius: 0;*/
    /*    border-top-left-radius: 0*/
    /*}*/

    .timeContainer {
        width: 100%;
        display: flex;
        margin-top: 0;
    }

    .timeTextsContainer {
        display: flex;
        width: 92%;
        margin-left: 4%;
        bottom: 6px;
    }

    .timeBar {
        margin-top: 16px;
    }

    .timeElapsedOnHoverContainer {
        bottom: auto;
    }


    .timeBar::-webkit-slider-thumb {
        display: flex;
        visibility: var(--timeBarThumbVisible);
    }

}