@import '../../shared/style/sizes.css';

.resultsContainer {
    width: var(--medium-screen-width);
    margin-bottom: 300px;
}

.bookBeatBannerContainer {
    --margin-top: 20px;
    margin-top: var(--margin-top);
    width: 909px;
    display: flex;

}

.bookBeatBanner {
    width: 90%;
}

.lowerLoadingContainer {
    display: flex;
    margin-bottom: 25px;
    margin-top: -275px;
}

.sortFilterContainer {
    width: 878px;
    display: flex;
    --margin-top: 20px;
    margin: var(--margin-top) auto auto;
}

.mainLoadingContainer {
    margin-top: 300px;
    margin-bottom: 1500px;
}

.noResultsContainer {
    margin-top: 200px;
    margin-bottom: 800px;
    width: 100%;
    display: flex;
}

.divider {
    margin-top: 20px;
}

@media (max-width: 480px) {
    .mainLoadingContainer {
        margin-top: 50%;
        margin-bottom: 500px;
        display: flex;
        width: 100%;
    }

    .resultsContainer {
        width: 100%;
        margin-bottom: 50px;
    }

    .lowerLoadingContainer {
        margin-top: 10px;
    }

    .sortFilterContainer {
        width: 91%;
        --margin-top: 10px;
        margin-top: var(--margin-top);
        display: flex;
    }

    .noResultsContainer{
        margin-top: 100px;
    }

    .divider {
        margin-top: 16px;
    }

    .bookBeatBannerContainer {
        --margin-top: 10px;
        margin-top: var(--margin-top);
        width: 100%;
        display: flex;
    }

    .bookBeatBanner {
        width: 100vw;
    }

}

