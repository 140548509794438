@import '../../../../shared/style/colors.css';
@import '../../../../shared/style/mobileColors.css';


.searchBar {
    border-radius: 50px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 48px;
    display: flex;
    margin: auto;
    background: #FFFFFF;
}

.searchBar:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
}


.searchBar:focus {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
}

.placeholder {
    margin: auto;
}

.searchImage {
    margin: auto;
    width: 20px;
    height: 20px;
    color: var(--accent-color);
}

.leftContainer {
    display: flex;
    margin: auto auto auto 12px;
    width: auto;
}

.input {
    background: none;
    height: 40px;
    border: 0;
    outline: none;
    margin-left: 12px;
    width: 480px;
}

.input::placeholder {
    color: var(--second300);
}

.rightContainer {
    display: flex;
    border-left: 1px solid var(--second100);
    height: 100%;
    margin: auto 0 auto auto;
    width: 114px;
}

.searchButton {
    margin: auto 0 auto auto;
    width: 48px;
    height: 48px;
    background: none;
    border: none;
    cursor: pointer;
}





@media (max-width: 480px) {
    .searchBar {
        width: 100%;
        height: 34px;
        box-shadow: 0 0 6px rgba(11, 26, 51, 0.2);
        margin: auto;
    }

    .input {
        width: 100%;
        height: 30px;
        margin-left: 6px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .input::placeholder {
        max-width: 90%;
    }

    .leftContainer {
        height: 100%;
        width: 100%;
    }


    .searchImage {
        width: 14px;
        margin-left: 0;
        margin-right: 0;
    }

    .placeholder {
        width: 100%;
        margin-left: 0;
        padding-left: 0;
    }

    .rightContainer {
        display: flex;
        border-left: 1px solid #E7E8EB;
        width: 38%;
        margin-left: 0;
        margin-right: 0;
        text-align: center;
    }



}