@import '../../../shared/style/colors.css';
@import '../../../shared/style/sizes.css';


.footer {
    display: flex;
    height: var(--header-footer-height);
}


.container {
    display: flex;
    width: 600px;
}

.navItem {
    text-decoration: none;
    cursor: pointer;
}

@media (max-width: 480px) {
    .footer {
        height: 48px;
        margin-top: 50px;
    }

    .navItem {
        font-size: 10px;
    }

    .container {
        width: 90%;
    }

    .copyright {
        font-size: 10px
    }
}
