

.filterButton {
    --nonActiveColor: var(--second400);
    --activeColor: var(--main400);
    --currentColor: var(--nonActiveColor);
    display: flex;
    width: 100%;
    height: 100%;
    background: none;
    border: none;
    margin: auto;
    padding-left: 10%;
    padding-right: 6%;
    cursor: pointer;
}

.filterText {
    color: var(--currentColor);
    font-family: "Roboto Medium", serif;
    font-size: 12px;
}

.arrowContainer {
    height: 100%;
    margin-left: 0;
    display: flex;
}

.arrow {
    width: 12px;
    height: 12px;
}



@media (max-width: 480px) {
    .filterButton {
        display: flex;
        width: 100%;
        height: 100%;
        background: none;
        border: none;
        margin: auto;
        padding-left: 10%;
        padding-right: 6%;
    }

    .filterText {
        font-size: 11px;
    }

    .arrow {
        width: 10px;
    }

}