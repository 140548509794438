

.container {
    display: flex;
    height: 28px;
    width: fit-content;
    padding-left: 12px;
    padding-right: 12px;
    margin-left: 0;
    margin-right: 8px;
    margin-top: 0;
    outline: none;
    background: none;
    border: none;
    background: var(--main400);
    border-radius: 30px;

}

.closeContainer {
    margin: auto;
    float: left;
    display: flex;
}

.closeImage {
    width: 10px;
    height: 10px;
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(206deg) brightness(109%) contrast(101%);
}

.titleContainer {
    margin-left: 6px;
    color: var(--whitePearch);
}


@media (max-width: 480px) {
    .container {
        height: 26px;
        margin-top: 10px;
        margin-bottom: -2px;
    }

    .closeImage {
        width: 8px;
        height: 8px;
        margin-right: 4px;
    }

    .titleContainer {
        font-size: 11px;
        margin-left: 2px;
    }

}




