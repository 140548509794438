.buttonContainer {
    --width: 148px;
    display: flex;
    position: absolute;
    right: 50%;
    margin-right: calc(var(--width) * (-1)/2);
    width: 148px;
    height: 54px;
    --buttonWidth: 24px;
}

.button {
    width: var(--buttonWidth);
    height: var(--buttonWidth);
    cursor: pointer;
}


@media (max-width: 480px) {
    .buttonContainer {
        right: 26%;
        width: 130px;
    }

    .playButton {
        display: flex;
        width: 24px;
        height: 24px;
    }

    .backwardForwardButton {
        width: 22px;
        height: 22px;
    }

    .pauseButton {
        display: flex;
        width: 24px;
        height: 24px;
    }



}