

.container{
    width: 310px;
    height: 48px;
    border-radius: 30px;
    display: flex;
    border: none;
    background: var(--main400);
    box-shadow: 0 0 10px rgba(11, 26, 51, 0.10)
}


.text {
    color: var(--whitePearch);
    font-family: "Roboto", serif;
    font-size: 14px;
    margin-left: 0;
}

.searchImage {
    margin: 16px;
    width: 16px;
}