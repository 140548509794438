
.container{
    width: fit-content;
    margin: auto;
}

.button {
    display: flex;
    height: 30px;
    width: fit-content;
    padding-left: 16px;
    padding-right: 16px;
    margin-left: 0;
    margin-right: 12px;
    margin-top: 0;
    outline: none;
    background: none;
    border: none;
    background: var(--main100);
    border-radius: 30px;
    cursor: pointer;
}



.titleContainer {
    font-weight: 800;
    color: var(--main400);
    font-size: 13px;
}


.arrow {
    margin-left: 6px;
    flex: none;
    order: 1;
    flex-grow: 0;
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid var(--main400);
}

.openedArrow {
    transform: rotate(180deg);
}

.sortImageOpened {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.sortImageContainer {
    display: flex;
    width: fit-content;
    margin-right: 8px;
}

@media (max-width: 480px) {
    .button {
        padding-left: 12px;
        padding-right: 12px;
    }

    .titleContainer {
        font-size: 12px;
    }

    .container {
        padding-left: 0;
    }

    .button {
        height: 26px;
    }

    .arrow {
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 4px solid var(--main400);
    }
}