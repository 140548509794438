@keyframes quiet {
    25%{
        transform: scaleY(.6);
    }
    50%{
        transform: scaleY(.4);
    }
    75%{
        transform: scaleY(.8);
    }
}

@keyframes normal {
    25%{
        transform: scaleY(1);
    }
    50%{
        transform: scaleY(.4);
    }
    75%{
        transform: scaleY(.6);
    }
}
@keyframes loud {
    25%{
        transform: scaleY(1);
    }
    50%{
        transform: scaleY(.4);
    }
    75%{
        transform: scaleY(1.2);
    }
}

.body{
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 0;
    align-items: center;
}

.boxContainer{
    transform: scaleY(0.9);
    display: flex;
    justify-content: space-between;
    height: 24px;
    --boxSize: 4px;
    --gutter: 1px;
    width: calc((var(--boxSize) + var(--gutter)) * 5);
}

.box{
    transform: scaleY(.4);
    height: 100%;
    width: var(--boxSize);
    background: var(--second500);
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    border-radius: 8px;
}

.box1{
    animation-name: quiet;
}

.box2{
    animation-name: normal;
}

.box3{
    animation-name: quiet;
}

.box4{
    animation-name: loud;
}

.box5{
    animation-name: quiet;
}

@media (max-width: 480px) {

    .boxContainer{
        transform: scaleY(0.7);
        --boxSize: 3px;
        --gutter: 1px;
    }

}