
.textContainer {
    margin-bottom: 0;
    margin-left: 0;
    width: 470px;
    height: fit-content;
}

.titleContainer {
    font-family: "Rosa Sans SemiBold", serif;
    font-size: 46px;
    line-height: 130%;
    letter-spacing: 0.01em;
}

.bodyContainer {
    margin-top: 36px;
    font-size: 20px;
    line-height: 180%;
    color: var(--second400);
}

.textContainerReversed {
    margin: auto 0 0 auto;
}


@media (max-width: 480px) {
    .textContainer {
        width: 100%;
    }

    .titleContainer {
        font-size: 28px;
    }

    .bodyContainer {
        font-size: 18px;
    }
}